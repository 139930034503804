<template>
  <multilingual-settings-container
    v-model="selectedLanguage"
    :settings="settings"
    :isLoading="isTranslating"
    :otherLanguages="otherLanguages"
    :currentTab="currentTab"
    :isDisabled="isDisabled"
    @clickTranslateAll="translateAllField"
    @save="save()"
    @close="onClose"
    @updateCurrentTab="updateCurrentTab"
  >
    <template #origin-content>
      <div class="mt-8 flex flex-col gap-20 mb-8">
        <div class="flex flex-col gap-4">
          <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
            {{ $t('studio.ai_translation.title2_product') }}
          </p>
          <div class="flex">
            <div class="flex-1">
              <input-text
                v-model="defaultLanguage.productName"
                size="lg"
                variant="outline"
                placeholder="placeholder"
                disabled
              />
            </div>
            <s-tooltip
              arrow
              :content="$t('studio.ai_translation.badge')"
              :duration="0"
              :distance="4"
              useFlip
              flipOnUpdate
              placement="top"
              trigger="mouseenter focus"
              :theme="'studio-tooltip'"
              :zIndex="2501"
              :allowHTML="true"
            >
              <template #target>
                <button
                  type="button"
                  :disabled="!isProductWritable || isDisabled"
                  class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                  @click="translateSelectedField('productName', defaultLanguage.productName)"
                >
                  <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                </button>
              </template>
            </s-tooltip>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
            {{ $t('studio.ai_translation.title3_creator') }}
          </p>
          <div class="flex items-center">
            <div class="flex-1">
              <input-text
                v-model="defaultLanguage.developer"
                size="lg"
                variant="outline"
                placeholder="placeholder"
                disabled
              />
            </div>
            <s-tooltip
              arrow
              :content="$t('studio.ai_translation.badge')"
              :duration="0"
              :distance="4"
              useFlip
              flipOnUpdate
              placement="top"
              trigger="mouseenter focus"
              :theme="'studio-tooltip'"
              :zIndex="2501"
              :allowHTML="true"
            >
              <template #target>
                <button
                  :disabled="!isProductWritable || isDisabled"
                  type="button"
                  class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                  @click="translateSelectedField('developer', defaultLanguage.developer)"
                >
                  <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                </button>
              </template>
            </s-tooltip>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
            {{ $t('studio.ai_translation.title4_distributor') }}
          </p>
          <div class="flex">
            <div class="flex-1">
              <input-text
                v-model="defaultLanguage.publisher"
                size="lg"
                variant="outline"
                placeholder="placeholder"
                disabled
              />
            </div>
            <s-tooltip
              arrow
              :content="$t('studio.ai_translation.badge')"
              :duration="0"
              :distance="4"
              useFlip
              flipOnUpdate
              placement="top"
              trigger="mouseenter focus"
              :theme="'studio-tooltip'"
              :zIndex="2501"
              :allowHTML="true"
            >
              <template #target>
                <button
                  type="button"
                  :disabled="!isProductWritable || isDisabled"
                  class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                  @click="translateSelectedField('publisher', defaultLanguage.publisher)"
                >
                  <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                </button>
              </template>
            </s-tooltip>
          </div>
        </div>
      </div>
    </template>
    <template #translated-content>
      <v-field-array name="languages">
        <s-tab-panel
          v-for="(lang, idx) in settingLanguages"
          :key="lang.key"
          :value="lang.value.langCode"
        >
          <div class="mt-36 flex flex-col gap-48 mb-8">
            <div class="w-full">
              <input-text
                :name="`languages[${idx}].productName`"
                label=""
                labelClass="''"
                maxLength="50"
                absoluteErrorMsg
                :disabled="!isProductWritable || isDisabled"
                :rules="{
                  required: {
                    value: true,
                    message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg2_required_n')
                  },
                  regex: {
                    regex: REGEX_PRODUCT_NAME,
                    message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
                  },
                  max_length: 50
                }"
              />
            </div>
            <div class="w-full">
              <input-text
                :name="`languages[${idx}].developer`"
                label=""
                labelClass="''"
                maxLength="50"
                absoluteErrorMsg
                :rules="{
                  required: {
                    value: true,
                    message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg3_required_n')
                  },
                  max_length: 50
                }"
                :disabled="!isProductWritable || isDisabled"
              />
            </div>
            <div class="w-full">
              <input-text
                :name="`languages[${idx}].publisher`"
                label=""
                labelClass="''"
                maxLength="50"
                absoluteErrorMsg
                :rules="{
                  required: {
                    value: true,
                    message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_required_n')
                  },
                  max_length: 50
                }"
                :disabled="!isProductWritable || isDisabled"
              />
            </div>
          </div>
        </s-tab-panel>
      </v-field-array>
    </template>
  </multilingual-settings-container>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { type FieldEntry, useFieldArray, useForm, useSetFormErrors } from 'vee-validate';
import { computed, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';

import { checkProductInProjectNameApi } from '@/apis/project-product.api';
import { translateApi } from '@/apis/translation.api';
import MultilingualSettingsContainer from '@/components/common/multilingual-settings-container.vue';
import InputText from '@/components/validation/input-text.vue';
import { TRANSLATE_LANGUAGES } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { TranslationFormatType } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import { RuleNames } from '@/enums/validation.enum';
import useProductStore from '@/stores/product.store';
import type { ErrorResponse, LanguageModel } from '@/types/common/common.type';
import type { ProductLanguage } from '@/types/product/product-model.type';
import type { DuplicatedProductNameRequest } from '@/types/product/product-request.type';
import type { Translated } from '@/types/translation/translation.response';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  groupId: string;
  languages: ProductLanguage[];
  isDisabled?: boolean;
}>();
const emit = defineEmits<{
  close: [languages?: ProductLanguage[]];
}>();
const productStore = useProductStore();

const { isProductWritable } = storeToRefs(productStore);
const route = useRoute();
const productNo: number = !isNaN(Number(route.params.productId))
  ? Number(route.params.productId)
  : 0;

const { languages } = toRefs(props);
const isTranslating = ref<boolean>(false);
const currentTab = ref<string>(languages.value[0]?.langCode);
// code to translate
const fullLanguages: LanguageModel[] = TRANSLATE_LANGUAGES;

const defaultLanguage = ref<ProductLanguage>(
  languages.value.find((lang: ProductLanguage) => lang.default) || {
    langCode: LanguageCode.Ko,
    default: true,
    productName: '',
    developer: '',
    publisher: ''
  }
);

const createSettingLanguages = (): ProductLanguage[] => {
  const result = [];
  for (let i = 0; i < fullLanguages.length; i++) {
    if (fullLanguages[i].langCode === defaultLanguage.value.langCode) {
      continue;
    }
    const lang = languages.value.find(
      (lang: ProductLanguage) => lang.langCode === fullLanguages[i].langCode
    ) || {
      langCode: languages.value[i].langCode,
      default: false,
      productName: '',
      developer: '',
      publisher: ''
    };
    result.push({
      ...lang
    });
  }
  return result;
};

const { handleSubmit, errors, setValues, validateField } = useForm<{ languages: ProductLanguage[] }>({
  initialValues: {
    languages: createSettingLanguages()
  }
});
const { fields: settingLanguages, replace } = useFieldArray<ProductLanguage>('languages');
const setFormErrors = useSetFormErrors();

const settings = computed<ProductLanguage[]>(() =>
  settingLanguages.value.map((lang: FieldEntry<ProductLanguage>) => lang.value)
);

const selectedLanguage = ref<string>(settings.value[0].langCode);

const translateSelectedField = async (key: keyof ProductLanguage, text: string) => {
  isTranslating.value = true;
  const index = settings.value?.findIndex(
    (lang: ProductLanguage) => lang.langCode === selectedLanguage.value
  );
  const result = await translateApi({
    sourceLang: defaultLanguage.value.langCode,
    targetLangs: [selectedLanguage.value],
    contents: {
      [key]: text
    },
    formats: {
      [key]: TranslationFormatType.TEXT
    }
  });
  isTranslating.value = false;
  if (!result) {
    return;
  }
  setValues({
    languages: settings.value.map((s: ProductLanguage, inx: number) => {
      if (inx === index) {
        return { ...s, [key]: result.translated[0]?.translatedContents[key] };
      }
      return { ...s };
    })
  }, false);
  await validateField(`languages.${index}.${key}`);
};

const otherLanguages = computed(() => {
  const errorKeys = Object.keys(errors.value);
  return fullLanguages.filter((lang: LanguageModel) => lang.langCode !== defaultLanguage.value.langCode).map((l: LanguageModel, index: number) => {
    const hasError = errorKeys.some((key: string) => key.includes(`languages[${index}]`));
    return { ...l, hasError };
  });
});

const translateAllField = async () => {
  isTranslating.value = true;

  const keys = Object.keys(defaultLanguage.value).filter(
    (key: string) => key !== 'langCode' && key !== 'default'
  );

  const contents = keys.reduce((acc: Record<string, string | boolean | string[]>, key: string) => {
    acc[key] = defaultLanguage.value[key];
    return acc;
  }, {});

  const result = await translateApi({
    sourceLang: defaultLanguage.value.langCode,
    targetLangs: settings.value.map((lang: ProductLanguage) => lang.langCode),
    contents,
    formats: {
      productName: TranslationFormatType.TEXT,
      developer: TranslationFormatType.TEXT,
      publisher: TranslationFormatType.TEXT
    }
  });

  if (!result) {
    isTranslating.value = false;
    return;
  }

  const translatedLanguages: ProductLanguage[] = settingLanguages.value.map((lang: FieldEntry<ProductLanguage>) => {
    const translatedKeys = result.translated.find(
      (item: Translated) => item.lang === lang.value.langCode
    )?.translatedContents;
    const translatedProductLang = {
      ...lang.value,
      ...translatedKeys
    };
    return translatedProductLang;
  });

  setValues({ languages: translatedLanguages });
  replace(translatedLanguages);
  isTranslating.value = false;
};

const updateCurrentTab = (value: string) => {
  currentTab.value = value;
};

const save = handleSubmit(async ({ languages }: { languages: ProductLanguage[] }) => {
  const errorMessage = generateErrorMsg(
    'studio.prj_prod.this_prod.home_product_setting_val_duplication_msg',
    RuleNames.CHECK_DUPLICATE
  );
  let hasError = false;

  for (let i = 0; i < languages.length; i++) {
    try {
      const params: DuplicatedProductNameRequest = {
        groupId: props.groupId,
        productName: languages[i].productName,
        languageCd: 'ALL'
      };
      if (productNo) {
        params.exceptProductNo = productNo;
      }
      await checkProductInProjectNameApi(params);
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      if (errorCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
        setFormErrors({
          [`languages[${i}].productName`]: errorMessage
        });

        if (!hasError) {
          currentTab.value = languages[i].langCode;
          hasError = true;
        }
      }
    }
  }

  if (hasError) {
    return;
  }

  // emit to index
  emit('close', [defaultLanguage.value, ...languages]);
});

const onClose = () => {
  if (isTranslating.value) {
    return;
  }

  emit('close');
};
</script>
